<template>
  <section class="invoice-preview-wrapper">

    <div id="print">
      <div
        class="centraTabla"
        style="text-align: center !important;"
      >
        <table
          id="tg"
          class="center-block"
          cellspacing="0"
          cellpadding="0"
          align="center"
          style="float: center !important;width: 58mm; max-width: 58mm !important; border: 1px solid #cccccc;font-family:Gotham, Helvetica, Arial, sans-serif; font-size:10px;margin: 5px 0;"
        >
          <tbody>
            <tr>
              <td><br><br></td>
            </tr>
            <tr>
              <td
                style="font-size:48px; font-family: Gotham, Helvetica Neue, Helvetica, Arial, sans-serif;font-weight:600;text-align:center; padding:1px 0  6px 3px;"
              >
                {{ pedidoId }}</td>
            </tr>
            <tr>
              <td style="border-bottom: 1px dashed #000; padding-bottom: 10px;">
                {{ ticketPedido ? ticketPedido.id : '' }}
              </td>
            </tr>
            <tr>
              <td
                style="padding-top: 7px;text-align:center;font-size:16px;font-family: Gotham, Helvetica, Arial, sans-serif;text-transform: capitalize;font-weight: 300;"
              >
                {{ ticketPedido ? ticketPedido.cliente_nombre : '' }} </td>
            </tr>
            <tr>
              <td style="padding-bottom: 10px;"> <span
                style="white-space:nowrap;font-size:16px;"
              >{{ ticketPedido ? ticketPedido.cliente_dir_calle + ', ' + ticketPedido.cliente_dir_numero : '' }}</span><br> <br><br> <span
                style="white-space:nowrap;"
              >{{ fechaPedido }}</span><br> </td>
            </tr>

            <template
              v-for="(item, n) in productosTickets"
            >

              <tr :key="(n * 10) + 1">
                <td style="border-top: 1px dashed #000;padding-top: 7px;text-align:center;font-size:16px;font-family: Gotham,helvetica neue, helvetica, arial,sans-serif;text-transform:capitalize;font-weight:300;">
                  {{ item.refer }} - {{ item.uds }} uds
                </td>
              </tr>
              <tr :key="100 + (n * 10)">
                <td style="">
                  {{ item.name }}
                </td>
              </tr>
              <template v-for="(lote, i) in item.lotes">
                <tr :key="(i * 10) + 200 + (n * 10)">
                  <td style="padding-top: 7px;text-align:center;font-size:16px;font-family: Gotham,helvetica neue, helvetica, arial,sans-serif;text-transform:capitalize;font-weight:300;">
                    {{ lote.lote }}
                  </td>
                </tr>
                <tr :key="(i * 10) + 300 + (n * 10)">
                  <td
                    id="divis2"
                    style="padding-bottom:10px;"
                  ><strong><span
                    style="white-space:nowrap;"
                  >{{ lote.fecha }}</span></strong></td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="ticketPedido === undefined || ticketPedido === null"
    >
      <h4 class="alert-heading">
        Error fetching order data
      </h4>
    </b-alert>

    <b-row
      v-if="ticketPedido"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        md="4"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- <div class="todo"> -->

              <div class="text-center">
                <p class="id-pedido">
                  {{ pedidoId }}
                </p>
                <p>
                  {{ ticketPedido ? ticketPedido.id : '' }}
                </p>
                <p class="mb-1">
                  <br>
                  <strong>{{ ticketPedido ? ticketPedido.cliente_nombre : '' }}</strong>
                  <br>
                  {{ ticketPedido ? ticketPedido.cliente_dir_calle + ', ' + ticketPedido.cliente_dir_numero : '' }}
                  <br>
                  <br>
                  {{ fechaPedido }}
                </p>
                <p class="centrado">
                  <template v-for="(item, n) in productosTickets">
                    <strong :key="n + 1">
                      {{ item.refer }} - {{ item.uds }} uds<br>
                    </strong>
                    <span
                      v-for="(lote, i) in item.lotes"
                      :key="i * 10 + n * 10"
                    >
                      {{ lote.lote }} - {{ lote.fecha }}<br>
                    </span>
                  </template>
                </p>
              </div>
            </div>
            <!-- </div> -->
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimir
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <!-- <invoice-sidebar-send-invoice />
    <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import router from '@/router'
// import store from '@/store'
// import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BButton, BAlert, VBToggle,
} from 'bootstrap-vue'
// import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import usePedidos from './usePedidos'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BAlert,

    // Logo,
  },
  setup() {
    const invoiceData = ref(null)

    const pedidoId = ref('')
    const fechaPedido = ref('')
    const productosTickets = ref([])

    const {
      ticketPedido,
      getTicketPedido,
      productosList,
    } = usePedidos()

    const fechaHoraCortas = f => {
      const fecha = new Date(f) || new Date()
      return new Intl.DateTimeFormat('es-ES', { dateStyle: 'short', timeStyle: 'short' }).format(fecha)
    }

    const setTicket = () => {
      const { id, productos } = ticketPedido.value
      const splited = id.split('R')[1]
      pedidoId.value = splited
      fechaPedido.value = fechaHoraCortas(ticketPedido.value.fecha)
      const result = []
      productos.forEach(p => {
        const { ref: refer, uds, lines } = p
        const { name } = productosList.value.filter(c => c.id === refer)[0]
        const lotesControl = []
        const lotes = []
        if (lines && lines.length) {
          lines.forEach(l => {
            const { lote, fecha } = l
            if (!lotesControl.includes(lote)) {
              lotesControl.push(lote)
              lotes.push({
                lote,
                fecha,
              })
            }
          })
        }
        result.push({
          refer,
          uds,
          lotes,
          name,
        })
      })
      productosTickets.value = result
    }

    watch(ticketPedido, newValue => {
      if (newValue) setTicket()
    })

    // UnRegister on leave
    onMounted(() => {
      const { id } = router.currentRoute.params
      if (!id) {
        console.log('Error: Se necesita un id de pedido')
        return
      }
      getTicketPedido(id)
      // getTicketPedido('abcd')
    })

    const printInvoice = () => {
      window.print()
    }

    return {
      invoiceData,
      printInvoice,
      ticketPedido,
      pedidoId,
      fechaPedido,
      productosTickets,
      productosList,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">

.id-pedido {
  margin: 1rem;
  background-color: black;
  color: white;
  font-size: 3rem;
  padding: 2rem;
  font-weight: 600;
}
.invoice-preview-card {
  max-width: 300px !important;
}
@media screen {
  #print {
    display: none;
   }
}
@media print {
  @page {
    size: 58mm 210mm;
  }
  * {
    margin: 0 !important;
    padding: 0!important;
  }
  body {
    visibility:hidden;
    background-color: transparent !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
  }
}
@media print {
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
  }
  body {
    margin: 0;
  }
  main {
    display: block;
  }
  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  a {
    background-color: transparent;
  }
  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }
  b,
  strong {
    font-weight: bolder;
  }
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  small {
    font-size: 80%;
  }
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  img {
    border-style: none;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }
  progress {
    vertical-align: baseline;
  }
  textarea {
    overflow: auto;
  }
  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }
  details {
    display: block;
  }
  summary {
    display: list-item;
  }
  template {
    display: none;
  }
  [hidden] {
    display: none;
  }
  @media print {

    *,
    *:before,
    *:after {
      color: #000 !important;
      text-shadow: none !important;
      background: transparent !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " ("attr(href) ")";
    }

    abbr[title]:after {
      content: " ("attr(title) ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
      content: "";
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }

    .navbar {
      display: none;
    }

    .btn>.caret,
    .dropup>.btn>.caret {
      border-top-color: #000 !important;
    }

    .label {
      border: 1px solid #000;
    }

    .table {
      border-collapse: collapse !important;
    }

    .table td,
    .table th {
      background-color: #fff !important;
    }

    .table-bordered th,
    .table-bordered td {
      border: 1px solid #ddd !important;
    }
  }

  @media print {
    .visible-print {
      display: block !important;
    }

    table.visible-print {
      display: table !important;
    }

    tr.visible-print {
      display: table-row !important;
    }

    th.visible-print,
    td.visible-print {
      display: table-cell !important;
    }
  }

  @media print {
    .visible-print-block {
      display: block !important;
    }
  }

  @media print {
    .visible-print-inline {
      display: inline !important;
    }
  }

  @media print {
    .visible-print-inline-block {
      display: inline-block !important;
    }
  }

  @media print {
    .hidden-print {
      display: none !important;
    }
  }
}
</style>
